

import './Rootlecrivain.scss';
export const Rootlecrivain = () =>
{
    return(
        <div className="Container Rootlecrivain">
            <h3 className="Rootlecrivain__Title">#rootlecrivain</h3>
            <p>Pas encore... mais ça arrive 🤙</p>
        </div>
    )
}

