

import './RootYoutubeur.scss';
export const RootYoutubeur = () =>
{
    return(
        <div className="Container RootYoutubeur">
            <h3 className="RootYoutubeur__Title">#rootleyoutubeur</h3>
            <p>Pas encore... mais ça arrive 🤙</p>
        </div>
    )
}
