
import './Error.scss';
export const Error = ({msg}) =>
{
    return(
        <div className="Error">
            <p>{msg}</p>
        </div>
    )
}
