

import './Tutos.scss';
export const Tutos = () =>
{
    return(
        <div className="RootLeDev__Tutos">
            <p>Il n'y a rien ici pour l'instant, mais ca arrive.. 🥺</p>
        </div>
    )
}
